import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import bgImg from "../images/404-bg.png";
import {
  lightBlue,
  darkBlue,
  mainWhite,
  Subtitle,
  screen,
} from "../components/common/variables";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${mainWhite};
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 35px;
  padding-right: 35px;
  @media ${screen.medium} {
    padding-top: 150px;
    padding-bottom: 85px;
  }
  @media ${screen.xLarge} {
    padding-top: 170px;
    padding-bottom: 110px;
    padding-left: 0;
    padding-right: 0;
  }

  .row-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .texts-container {
    max-width: 700px;
    align-self: center;
    margin-bottom: 45px;
    order: 1;
    @media ${screen.medium} {
      margin-bottom: 30px;
      order: 0;
    }
    @media ${screen.xLarge} {
      max-width: 670px;
      margin-bottom: 0;
    }

    h3 {
      color: ${lightBlue};
      font-size: 2rem;
      font-weight: 400;
      @media ${screen.medium} {
        font-size: 1.69rem;
        margin-bottom: 20px;
      }
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.7rem;
      max-width: 550px;
      margin-right: 45px;
      @media ${screen.medium} {
        font-size: 2.3rem;
      }
      @media ${screen.large} {
        font-size: 3rem;
      }
    }

    p {
      font-size: 1.28rem;
      color: ${darkBlue};
      font-weight: 400;
      margin-top: 5px;
      @media ${screen.medium} {
        font-size: 1.69rem;
        margin-top: 20px;
      }
      @media ${screen.xLarge} {
        font-size: 2rem;
      }
    }
  }

  .img-container {
    width: 100%;
    max-width: 370px;
    margin: 20px;
    @media ${screen.medium} {
      max-width: 570px;
      margin: 0 20px;
    }
    @media ${screen.xLarge} {
      max-width: 540px;
      margin: 0;
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Wrapper>
        <div className="row-container">
          <div className="texts-container">
            <h3>404</h3>
            <Subtitle className="subtitle" dark>
              You weren't meant to see this image...
            </Subtitle>
            <p>or this page as a matter of fact!</p>
          </div>
          <div className="img-container">
            <StaticImage
              src="../images/global-adviser-alpha-great-outcomes.png"
              alt="David Haintz"
              placeholder="blurred"
            />
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFoundPage;
